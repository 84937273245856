<template>
  <div class="">
    <Breadcrumbs title="Attendance Registry" />
    <div class="container-fluid">
      <b-row>
        <b-col cols="12">
          <div class="card">
            <div class="card-header"><h5>Attendance</h5></div>
            <div class="card-body">
              <div class="table-responsive">
                <b-table
                    ref="marker_table"
                    show-empty
                    selectable
                    :items="tableRecords"
                    :fields="tableFields"
                    @row-selected="rowSelected"
                    selected-variant="success"
                >
                  <template #cell(details)="row">
                    <b-row>
                      <b-col cols="12">{{row.item.name}}</b-col>
                      <b-col cols="12"><strong>Teacher %: </strong>{{row.item.teacher_percentage}}</b-col>
                      <b-col cols="12"><strong>Fee: </strong>Rs. {{row.item.class_fee}}</b-col>
                    </b-row>
                  </template>
                  <template #cell(action)="row" class="text-right">
                    <b-button-group>
                      <b-button class="btn-square mr-1 ml-1" variant="primary" @click="markAttendance(row.index, row.item, $event.target)" title="Add Free Card">Mark</b-button>
                    </b-button-group>
                  </template>
                </b-table>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import CRUD_API from '@/services/crud'
import moment from 'moment'
import API from "@/services/api";
import {checkUserPermissions} from "@/plugins/functions";
export default {
  name: "AttendanceMarker",
  props: [ 'student_id', 'institute_id', 'institutes' ],
  computed: {
    ...mapState({ user: state => JSON.parse(state.auth.user) }),
    ...mapGetters({ freeCardTypes: 'select/free_card_types' }),
  },
  mounted() {
    if (this.student_id) {
      this.fetchData()
    }
  },
  data(){
    return {
      selected: [],
      tableRecords: [],
      tableFields: [
        // { key: 'id', label: 'ID', sortable: false },
        { key: 'details', label: 'Name', sortable: true },
        { key: 'discount_type', label: 'Disc. Type', sortable: false, },
        { key: 'discount_value', label: 'Disc. Value', sortable: false, },
        { key: 'payable', label: 'Payable', sortable: false, },
        { key: 'action', label: 'Action', sortable: false, class: 'text-right text-capitalize' }
      ],

    }
  },
  methods: {
    checkUserPermissions,
    rowSelected(item) {
      this.selected = item
    },
    async fetchData() {
      await CRUD_API.index('/api/backend/pages/classes', {
        params: {
          per_page: 50,
          page: 1,
          institute_id: this.institute_id,
          student_id: this.student_id
        }
      }).then((result) => {
        this.tableRecords = result.data.data.data
      }).catch((error) => {
        console.log(error)
      }).finally(() => {
        this.$refs.marker_table.refresh()
      })
    },
    markAttendance() {}
  }
}
</script>

<style scoped>

</style>